.Subscription {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  padding-top: 7vh;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 5vh;
}

.SubscriptionCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10vh;
  gap: 20px;
}

@media (max-width: 767px) {
  .text {
    font-size: 24px;
  }

  .SubscriptionCard {
    margin-bottom: 100px;
  }
}
