* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1064px;
  padding-inline: 15px;
  margin-inline: auto;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* IE 10+ */
input[type="number"] {
  -ms-appearance: none;
}
