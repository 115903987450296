.Nav {
  color: #fff;
  display: flex;
  justify-content: space-between;
  background-color: #181818;
  width: 100%;
  position: fixed;
  z-index: 10;
}

.NavBobile {
  display: flex;
  justify-content: center;
  z-index: 10;
}
.NavInside {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.logo {
  margin: 0 0 0 10rem;
}

.title {
  /* margin: 1rem 0 0 0; */
  padding: 0 10rem 0 0;
}

.link {
  color: #fff;
  text-decoration: none;
}

.hover {
  border-bottom: 2px solid #2ecc71;
  padding-bottom: 1rem;
  background-color: #2a2a2a;
  padding: 1rem;
}

.btn {
  /* border-bottom: 2px solid #2ECC71; */
  padding-bottom: 1rem;
  /* background-color: red; */
  padding: 1rem;
}
