.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
}

.Main {
  background-color: #000;
  padding: 0 0 3rem 0;
}

.text {
  padding: 0 14px 0 14px;
  border-radius: 40px;
  background-color: #212121;
  margin: 10px 0 0 0;
}
.text1 {
  padding: 0 14px 0 14px;
  border-radius: 40px;
  background-color: #212121;
  margin: 10px 0 0 0;
  color: #f57c7c;
}

.Table {
  border-radius: 25px !important;
  background-color: #333333;
  margin: 10px 0 0 0;
}

.CardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.Card {
  background-color: #191919;
  color: #fff;
  flex: 1 1 45%;
  max-width: 48%;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.LogoutButton {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 14px;
  text-transform: none;
  cursor: pointer;
  margin-left: 32px;
  text-align: center;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.12);
}
.edit {
  color: #fff;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  cursor: pointer;

  display: flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.12);
  margin-bottom: 20px;
}

.feedbackPopUp {
  position: absolute;
  z-index: 1;
  right: 40px;
  bottom: 80px;
  background: #191919;
  border: 1px solid #202020;
  box-shadow: 0px 2px 16px -1px rgba(0, 0, 0, 0.18);

  iframe {
    width: 310px;
    height: 400px;
    border: none;
    background-color: #000;
  }
}

@media (max-width: 991px) {
  .Container {
    padding-inline: 20px;
    padding: 20px;
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .Card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
