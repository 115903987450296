.card {
  margin-bottom: 16px;

  .img {
    width: 300px;
    height: 268px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px 32px 0px 0px;
    /* margin-right: 20px; */
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 226px;
    border-radius: 0px 0px 32px 32px;
    background: #333;
    padding: 24px;

    .heading {
      color: #fcfcfc;
      font-family: "Work Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .desc {
      color: var(--neutral-24, #c8c8c8);
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
      margin-bottom: 24px;
    }

    .priceText {
      color: var(--neutral-24, #c8c8c8);
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
      margin-bottom: 16px;
    }

    .price {
      color: #2ecc71;
      font-family: "Work Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.42px;
      text-transform: capitalize;
      margin-left: 6px;
    }
  }
}

@media (max-width: 767px) {
  .card {
    margin-bottom: 20px;
  }
}
