.Main {
  background-color: #000;
  display: flex;
  justify-content: space-around;
  padding-bottom: 3rem;
}

.Box {
  padding: 2.5rem;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 12px;
  padding: 1.5rem 3rem 3rem 2.5rem;
  text-decoration: none;
  color: #ffffff;
}

.BoxClick {
  border: 1px solid #242424;
  padding: 1.5rem 3rem 3rem 2.5rem;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color:#242424 ;
  border-radius: 12px;
}
