.cardContainer {
  width: 100%;
  background-color: #212121;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #323232;
  padding: 10px;
}

.nflText {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.timeText {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
}

.matchDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teams {
  flex: 1;
  padding: 16px 22px;
  display: flex;
  justify-content: flex-start;
}

.teamText {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  max-width: 120px;
  word-wrap: break-word;
}

.odds {
  display: flex;
  align-items: center;
  color: white;
  background-color: #0f2e0f;
  padding-block: 25px;
}

.lockIcon {
  display: flex;
  gap: 10px;
  padding: 10px;
  height: 100%;
}

.oddsText {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.info {
  padding: 25px 16px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.infoText {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  flex: 1;
}

.readMoreLink {
  color: #2ecc71;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  border-bottom: 1px solid #2ecc71;
  display: inline-block;
}

.readMoreLink:hover {
  text-decoration: underline;
  color: #2ecc71;
}

@media (max-width: 768px) {
  .infoText {
    /* width: 180px; */
  }

  .odds {
    flex-direction: column;
  }

  .teamText {
    /* max-width: 5%; */
    word-wrap: break-word;
    max-width: 100px;
  }

  .teams {
    padding: 10px 12px;
  }
}
