.card {
  width: 100%;
  background-color: #212121;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px; /* Adds space between cards */
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #323232;
  padding: 10px;
}

.nflText {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.timeText {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
}

.body {
  display: flex;
  justify-content: space-between;
}

.player {
  flex: 1;
  margin-right: 20px;
  text-align: left;
  margin-top: 10px;
  padding-inline: 22px;
  margin-bottom: 10px;
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 28px;
}

.content {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  text-align: left;

  border-radius: 8px;
}

.infoText {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
  flex: 1;
}

.overSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.locks {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 10px;
  padding: 10px;
}

.overText {
  color: white;
  font-size: 14px;
  font-weight: 500;
  background: rgba(0, 255, 0, 0.12);
  height: 61px;
  padding: 10px 16px;
  justify-content: baseline;
  align-items: center;
  gap: 10px;
  display: flex;
  border-radius: 3px;
  text-align: right;
}

.readMoreLink {
  color: #2ecc71;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
}

.readMoreLink:hover {
  text-decoration: underline;
  color: #2ecc71;
}

@media (max-width: 767px) {
  .body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .overSection {
    flex-direction: row;

    > * {
      flex-basis: 50%;
      padding-inline: 20px;
    }
  }
}

@media (max-width: 768px) {
  .infoText {
    width: 120px;
  }
}
