.wrappper {
  max-width: 1064px;
  /* padding-inline: 15px; */
  margin-inline: auto;
}

.Box {
  background-color: #191919;
  padding: 15px 40px 15px 30px;
  margin: 10%;
  max-width: 22rem;
}

.BoxLap {
  background-color: #191919;
  padding: 15px 40px 15px 30px;
  margin: 15px;
  width: 35rem;
  height: 25rem;
  border-radius: 15px;
}

.Container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.Cart {
  max-width: 22rem;
}

.ContainerMobile {
  border: 1px solid transparent;
}

.welcome {
  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 51.2px;
  margin-bottom: 60px;
  padding: 2 0 0 0;
}

@media (max-width: 767px) {
  .welcome {
    font-size: 24px;
    margin-bottom: 12px;
  }
}
